
import { defineComponent, ref, watch } from "vue";
import { InvoiceType } from "@/core/config/Constant";

export default defineComponent({
  name: "InvoiceLabel",
  props: {
    code: {type: String, required: true}
  },
  setup(props) {
    const label = ref('');
    const buildLabel = (code: string) => {
      switch (code) {
        case InvoiceType.UB04:
          label.value = 'UB04';
          break
        case InvoiceType.HCFA:
          label.value = 'HCFA';
          break
        case InvoiceType.DENTAL:
          label.value = 'Dental';
          break
        case InvoiceType.STATEMENT:
          label.value = 'Statement';
          break
        case InvoiceType.TRIP_CAN:
          label.value = 'Trip Cancellation';
          break
        case InvoiceType.PRESCRIPT:
          label.value = 'Prescription Old';
          break
        case InvoiceType.RX:
          label.value = 'Prescription';
          break
        case InvoiceType.LOST_BAG:
          label.value = 'Lost Baggage';
          break
        case 'GENERIC':
          label.value = 'Generic';
          break
        case 'IVD':
          label.value = 'Vehicle Damage';
          break
        case 'IADD':
          label.value = 'AD&D';
          break
        case 'COB':
          label.value = 'COB';
          break
        case 'IT':
          label.value = 'Transportation';
          break
        case 'IMD':
          label.value = 'Medical Record';
          break
        case 'IAA':
          label.value = 'Air Ambulance';
          break
        case 'LA':
          label.value = 'Land Ambulance';
          break
        case 'IO':
          label.value = 'Other';
          break
        case 'AETNA':
          label.value = 'Aetna';
          break

      }
    }
    buildLabel(props.code);
    watch(() => props.code, (cb) => {
      buildLabel(cb);
    })
    return {
      label,
    }
  }
})
